import React, { useRef } from 'react'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import styles from "./HomePage.module.css"
import slack from "../../Assets/Home/slack.png"
import github from "../../Assets/Home/github.png"
import gitlab from "../../Assets/Home/gitlab.png"
import kube from "../../Assets/Home/kube.png"
import helm from "../../Assets/Home/helm.png"
import argo from "../../Assets/Home/argo.png"
import analyse from "../../Assets/Home/analyse.svg"
import colab from "../../Assets/Home/colab.svg"
import analytics from "../../Assets/Home/analytics.svg"
import Experience from '../../Components/Experience/Experience'
import Component1 from './components/Component1/Component1'
import array2_image1 from "../../Assets/Home/array2_image1.png"
import array2_image2 from "../../Assets/Home/array2_image2.png"
import array2_image3 from "../../Assets/Home/array2_image3.png"
import create_ephemeral from "../../Assets/Home/create_ephemeral.png"
import release_approvals from "../../Assets/Home/release_approvals.png"
import github_action from "../../Assets/Home/github_action.png"
import CustomerCard from '../../Components/CustomerCard/CustomerCard'
import LastContainer1 from '../../Components/LastContainer/LastContainer1'
import PricingPlan from '../../Components/PricingPlan/PricingPlan'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

const HomePage = () => {
  const navigation = useNavigate()
  const sliderRef1 = useRef()
  const titleDetails = {
    title: "Reliza Hub - DevOps Orchestration Platform",
    titleClass: styles.C1_title,
    titleMaxWidth: "925px",
    text: [
      {
        text: "Comprehensive release management and continuous deployment solution. Built for regulated industries.",
        maxWidth: "611px",
        textClass: `mx-auto ${styles.C1_text}`
      }
    ]
  }

  const favApps = [
    { icon: kube },
    { icon: helm },
    { icon: argo },
    { icon: slack },
    { icon: github },
    { icon: gitlab }
  ]
  const featuresArray = [
    {
      icon: analyse,
      title: "Analyze your data",
      text: "Create reports with an easy to use drag-and-drop designer.",
    },
    {
      icon: colab,
      title: "Collaborate securely",
      text: "Share/publish your reports with your colleagues.",
    },
    {
      icon: analytics,
      title: "Embedded analytics",
      text: "Get a powerful analytics tool in your own brand name.",
    },
  ]
  const array2 = [
    {
      image: create_ephemeral,
      title: "On-demand Ephemerals and Instance Management",
      texts: [
        { text: "Reliza Hub supports spinning on-demand Ephemeral instances as well as managing persistent instances with Reliza CD." }
      ]
    },
    {
      image: release_approvals,
      title: "Single Truth for Release Approvals",
      texts: [
        { text: "What version of each application is good to go for each of your instances? Reliza Hub provides configurable programmatic and manual approvals." },
        { text: "Connect your GitOps tooling to Reliza Hub and feed approved image versions directly into your manifests. All container images are resolved to their sha256 digests for added security!", }
      ]
    },
    {
      image: array2_image2,
      title: "Automated Tracking of DevOps Metrics",
      texts: [
        { text: "Fully automated tracking of DevOps Metrics: Lead Times, Deployment Frequency, Release Frequency, Build Times and others." },
        { text: "Just start using Reliza Hub and it will auto-compute analytics for you!", }
      ]
    },
    {
      image: github_action,
      title: "CI/CD, GitOps on Steroids",
      texts: [
        { text: "Reliza Hub integrates with virtually any CI, CD and GitOps solution out there. If different teams in your organization use different CICD stacks, Reliza Hub is built to integrate all of them into single view." },
        { text: "Currently, we have readily-available integrations with the following tools: Kubernetes, Helm, Kustomize, ArgoCD, Jenkins, GitHub Actions, GitLab CI, CircleCI, Azure DevOps", }
      ]
    }
  ]
  const customerFeedbackArray = [
    {
      profilePic: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      name: "Mila McSabbu",
      jobProfile: "Freelance Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
      name: "Robert Fox",
      jobProfile: "UI/UX Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      name: "Mila McSabbu",
      jobProfile: "Freelance Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
      name: "Robert Fox",
      jobProfile: "UI/UX Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      name: "Mila McSabbu",
      jobProfile: "Freelance Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
      name: "Robert Fox",
      jobProfile: "UI/UX Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      name: "Mila McSabbu",
      jobProfile: "Freelance Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
      name: "Robert Fox",
      jobProfile: "UI/UX Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      name: "Mila McSabbu",
      jobProfile: "Freelance Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
    {
      profilePic: "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png",
      name: "Robert Fox",
      jobProfile: "UI/UX Designer",
      texts: [
        { text: "OMG! I cannot believe that I have got a brand new landing page after getting appmax. It was super easy to edit and publish." }
      ]
    },
  ]
  var settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: customerFeedbackArray?.length >= 3 ? 3 : customerFeedbackArray?.length,
    slidesToScroll: customerFeedbackArray?.length >= 3 ? 2 : customerFeedbackArray?.length - 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  return (
    <BasicLayout>
      <div className='mainPaddingContainer'>
        <div className={`container-fluid ${styles.container1}`}>
          <div className='row mx-auto' style={{ maxWidth: titleDetails?.titleMaxWidth }}>
            <div className='col-12 mb-4'>
              <h1 className={titleDetails?.titleClass}>{titleDetails?.title}</h1>
            </div>
            <div className='col-12'>
              {titleDetails?.text?.map((item) => {
                return (
                  <p className={item?.textClass} style={{ maxWidth: item?.maxWidth }}>{item?.text}</p>
                )
              })}
            </div>
          </div>
          <div className='d-flex justify-content-center'>
          <a href='https://app.relizahub.com' target="_blank" style={{textDecoration:"none"}}><button className={styles.btn_usingFree}>Start using for free</button></a>
            
          </div>
        </div>
        <div className={`d-flex justify-content-center`}>
          <iframe className={`${styles.videoContainer}`} src="https://player.vimeo.com/video/824249883?h=42aaaabbe8&title=0&byline=0&portrait=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Reliza Hub Ephemeral Creation"></iframe>
        </div>
        <div className={`container-fluid ${styles.container2}`}>
          <div className='row'>
            <h3 className={`text-center ${styles.C2_title}`}>Integrations</h3>
            <p className={styles.C2_text}>Connects with your favorite tools.</p>
            <div className={`d-flex justify-content-center ${styles.integrationsFlexWrap}`}>
              {favApps?.map((item) => {
                return (
                  <img src={item?.icon} alt="" className={styles.favAppIcons} />
                )
              })}
            </div>
          </div>
        </div>
        {/*
        <div className={`container-fluid ${styles.container3}`}>
          <div className='row'>
            <div className='col-12 col-sm-8'>
              <h3 className={`${styles.C2_title}`}>Features</h3>
              <p className={styles.C3_text1}>Our Solution <br className='d-none d-sm-block' /> for your business</p>
              <p className={styles.C3_text2}>We are self-service data analytics software that lets you create visually appealing data visualizations and insightful dashboards in minutes.</p>
              <button className={`${styles.C3_btn_moreFeatures} d-none d-sm-block`}>More Features</button>
            </div>
            <div className='col-12 col-sm-4'>
              <div className={`row ${styles.features_right}`}>
                {featuresArray?.map((item) => {
                  return (
                    <div className={styles.C3_right_card}>
                      <div className='d-flex'>
                        <div><img src={item?.icon} alt="" /></div>
                        <div className='ps-4'>
                          <h4 className={styles.featuresArray_title}>{item?.title}</h4>
                          <p className={styles.featuresArray_text}>{item?.text}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
              <button className={`${styles.C3_btn_moreFeatures} d-block d-sm-none`}>More Features</button>
            </div>
          </div>
        </div>
        <div className={`container-fluid ${styles.container4}`}>
          <Experience />
        </div>
              */}
      </div>
      <div className={`container-fluid ${styles.container4}`}>
        {array2?.map((item, index) => {
          return (
            <Component1 details={item} index={index} />
          )
        })}
      </div>
      <div className='mainPaddingContainer'>
        <div className={`container-fluid ${styles.container5}`}>
          {/*
          <div className='row'>
            <h3 className={`text-center ${styles.C2_title} text-capitalize`}>Testimonials</h3>
            <div className={`${styles.testimonialContainer}`}>
              <p className={`text-center ${styles.C5_text}`}>What our happy customer say</p>
              <div className='d-flex d-sm-none'>
                <button className={`${styles.btn_slider_left}`} onClick={() => sliderRef1.current.slickPrev()}><FaArrowLeft /></button>
                <button className={`${styles.btn_slider_right}`} onClick={() => sliderRef1.current.slickNext()}><FaArrowRight /></button>
              </div>
            </div>
            <div className="sliderContinerMain">
              <div className={styles.sliderContiner}>
                <Slider ref={sliderRef1} {...settings}>
                  {customerFeedbackArray?.map((item) => {
                    return (
                      <div>
                        <CustomerCard details={item} />
                      </div>
                    )
                  })}
                </Slider>
              </div>
              <div className='sliderBtnContainer d-none d-sm-block'>
                <button className={`btn_slider ${styles.btn_slider_left}`} onClick={() => sliderRef1.current.slickPrev()}><FaArrowLeft /></button>
                <button className={`btn_slider ${styles.btn_slider_right}`} onClick={() => sliderRef1.current.slickNext()}><FaArrowRight /></button>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <button className={styles.btn_allCustomers} onClick={() => navigation("/customers")}>See all customer</button>
            </div>
          </div>
                */}
        </div>
      </div>
      <div id="homePagePricing" className='mainPaddingContainer'>
        <div className={`container-fluid ${styles.container6}`}>
          <h4 className={`${styles.pricingPlan_title1} text-center`}>Pricing & Plans</h4>
          <h4 className={`mx-auto text-center ${styles.pricingPlan_title2}`} style={{ maxWidth: "600px" }}>Fixed predictable rates for any team</h4>
          <PricingPlan />
        </div>
      </div>
      <LastContainer1 />
    </BasicLayout>
  )
}

export default HomePage